import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VCard,[_c(VToolbar,{attrs:{"color":"blue lighten-2","cards":"","dark":"","flat":""}},[_c(VBtn,{attrs:{"color":"blue lighten-2"},on:{"click":function($event){return _vm.goBack()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VSpacer),_vm._v(" Resum material i feina "),_c(VSpacer),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"blue lighten-2","dark":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-menu")])],1)]}}])},[_c(VList,_vm._l((_vm.menuItems),function(item,index){return _c(VListItem,{key:index},[_c(VListItemIcon,[_c(VIcon,{on:{"click":function($event){return _vm.goMenu(item.action)}}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemTitle,{on:{"click":function($event){return _vm.goMenu(item.action)}}},[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_c(VSimpleTable,[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Descripció")]),_c('th',{staticClass:"text-right"},[_vm._v("Quantitat")])])]),_c('tbody',_vm._l((_vm.itemList),function(item){return _c('tr',{key:item.docLineId02,staticClass:"table-primary",on:{"click":function($event){return _vm.goEdit(item.docLineId, item.docLineId02)}}},[_c('td',{staticClass:"text-left"},[_c('p',{style:(_vm.getStyle(("" + (item.readOnly))))},[_vm._v(" "+_vm._s(item.description)+" ")])]),_c('td',{staticClass:"text-right"},[_c('p',{style:(_vm.getStyle(("" + (item.readOnly))))},[_vm._v(_vm._s(item.qty))])])])}),0)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }